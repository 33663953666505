import { render, staticRenderFns } from "./yj_gm_settled.vue?vue&type=template&id=78870b82&scoped=true"
import script from "./yj_gm_settled.vue?vue&type=script&lang=js"
export * from "./yj_gm_settled.vue?vue&type=script&lang=js"
import style0 from "./yj_gm_settled.vue?vue&type=style&index=0&id=78870b82&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78870b82",
  null
  
)

export default component.exports