<!--
 * @description:应急非煤培训定制机构入驻
!-->
<template>
    <div class="wdn-settled-main">
        <div class="wd-settled">
            <div class="settled-header">
                <p>入驻申请</p>
            </div>
            <el-form
                ref="myForm"
                :label-position="'left'"
                :rules="rules"
                label-width="162px"
                :model="formData"
                @submit.native.prevent
            >
                <el-form-item label="企业名称：" prop="name" class="hasTip">
                    <el-input
                        v-model="formData.name"
                        size="small"
                        placeholder="请输入企业名称"
                    ></el-input>
                </el-form-item>
                <p class="formItemTip" style="color: #999; font-size: 12px">
                    输入的名称与提交的营业执照副本上的名称保持一致
                </p>
                <el-form-item class="needRequred" label="企业类型:">
                    <el-row type="flex" justify="flex-start">
                        <el-col class="selectItem" style="margin-right: 24px">
                            <el-form-item prop="enterType1" style="margin-bottom: 0">
                                <el-select
                                    v-model="formData.enterType1"
                                    @change="handleEnterType1Change"
                                    size="small"
                                    placeholder="请选择企业类型"
                                >
                                    <el-option
                                        v-for="item in institutionsArr1"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <!-- <el-col class="selectItem">
                          <el-form-item style="margin-bottom: 0">
                            <el-select
                              v-model="formData.enterType2"
                              size="small"
                              placeholder="请选择二级企业类型"
                            >
                              <el-option
                                v-for="item in institutionsArr2"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </el-col> -->
                    </el-row>
                </el-form-item>
                <el-form-item label="联系人：" prop="userName">
                    <el-input
                        v-model="formData.userName"
                        size="small"
                        :maxlength="30"
                        placeholder="请输入联系人姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="联系电话："
                    prop="phone"
                    class="hasTip"
                    style="margin-bottom: 8px"
                >
                    <el-input
                        v-model.trim="formData.phone"
                        size="small"
                        placeholder="请输入手机号"
                    ></el-input>
                </el-form-item>
                <div class="authCode formItemTip">
                    <el-input
                        v-model="authCode"
                        maxlength="6"
                        size="small"
                        placeholder="请输入验证码"
                        style="width: 412px"
                    ></el-input>
                    <el-button
                        v-if="Countdown"
                        class="getcodeBtn getcodeBtnGray"
                        type="primary"
                        size="small"
                        @click="getAuthCode"
                    >{{ Countdown + "s" }}</el-button
                    >
                    <el-button
                        v-else-if="isFirstGetAuthcode"
                        class="getcodeBtn"
                        type="primary"
                        size="small"
                        @click="getAuthCode"
                    >获取验证码</el-button
                    >
                    <el-button
                        v-else
                        class="getcodeBtn"
                        type="primary"
                        size="small"
                        @click="getAuthCode"
                    >重新获取</el-button
                    >
                </div>

                <el-form-item
                    label="所属上级："
                    class="needRequred"
                    style="margin-bottom: 10px"
                >
                    <el-row type="flex" justify="space-between">
                        <el-col class="selectItem">
                            <el-form-item prop="city" style="margin-bottom: 0">
                                <el-select
                                    ref="selectCity"
                                    v-model="formData.city"
                                    size="small"
                                    placeholder="请选择市"
                                    @change="handleCityChange"
                                >
                                    <el-option
                                        v-for="item in cityList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col class="selectItem">
                            <el-form-item style="margin-bottom: 0">
                                <el-select
                                    v-model="formData.district"
                                    :disabled="!formData.city || disabledSelect"
                                    @change="handleDistrictChange"
                                    clearable=""
                                    size="small"
                                    placeholder="请选择区县"
                                >
                                    <el-option
                                        v-for="item in districtList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col class="selectItem">
                            <el-form-item style="margin-bottom: 0">
                                <el-select
                                    v-model="formData.street"
                                    :disabled="!formData.district || disabledSelect"
                                    size="small"
                                    clearable=""
                                    placeholder="请选择乡镇街道园区"
                                >
                                    <el-option
                                        v-for="item in streetList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="详细地址：" prop="addressDetail">
                    <el-input
                        v-model="formData.addressDetail"
                        size="small"
                        :maxlength="50"
                        placeholder="请输入详细地址"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="统一社会信用代码："
                    prop="organCode"
                    key="organCode"
                >
                    <el-input
                        v-model="formData.organCode"
                        size="small"
                        placeholder="请输入社会统一信用代码"
                    ></el-input>
                </el-form-item>
                <el-form-item label="营业执照副本：">
                    <el-upload
                        v-loading="licenseImageUploadLoading"
                        class="avatar-uploader"
                        :action="action"
                        :headers="headers"
                        accept="image/jpg, image/jpeg, image/png"
                        :show-file-list="false"
                        :on-success="licenseImageSuccess"
                        :before-upload="beforeLicenseImageUpload"
                        :on-error="
              () => {
                licenseImageUploadLoading = false;
              }
            "
                    >
                        <img
                            v-if="formData.businessLicenseImage"
                            :src="formData.businessLicenseImage"
                            class="avatar"
                        />
                        <div v-else>
                            <i class="el-icon-plus avatar-uploader-icon"></i>
                            <p class="uploadTip">点击上传</p>
                        </div>
                        <div slot="tip" class="el-upload__tip">
                            营业执照扫描件图片后上传，支持jpg、png格式，图片大小不超过5M。
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div class="settled-footer">
                <el-button type="primary" size="small" class="submitBtn" @click="submit"
                >提交申请</el-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import config from "@/config/url";
import { organCodeValidator } from "./../../../utils/validator";
export default {
    name: "formDialog",
    data() {
        const accountValidator = (rule, value, callback) => {
            const isPhone =
                /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/;
            if (!isPhone.test(value)) {
                callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
        return {

            // 60非煤-地下矿山 61非煤-露天矿山 62非煤-尾矿库
            institutionsArr1: [

                {
                    name: "地下矿山",
                    value: 60,
                },
                {
                    name: "露天矿山",
                    value: 61,
                },
                {
                    name: "尾矿库",
                    value: 62,
                },
                {
                    name: "地下矿山/露天矿山",
                    value: 63,
                },
                {
                    name: "地下矿山/尾矿库",
                    value: 64,
                },
                {
                    name: "露天矿山/尾矿库",
                    value: 65,
                },
                {
                    name: "地下矿山/露天矿山/尾矿库",
                    value: 66,
                },
            ],
            institutionsArr: [
                {
                    parentValue: 22,
                    name: "钢铁",
                    value: 23,
                },
                {
                    parentValue: 22,
                    name: "粉尘涉爆",
                    value: 24,
                },
                {
                    parentValue: 25,
                    name: "粉尘涉爆",
                    value: 26,
                },
                {
                    parentValue: 27,
                    name: "粉尘涉爆",
                    value: 28,
                },
                {
                    parentValue: 29,
                    name: "铝加工（深井铸造）",
                    value: 30,
                },
                {
                    parentValue: 29,
                    name: "粉尘涉爆",
                    value: 31,
                },
                {
                    parentValue: 32,
                    name: "粉尘涉爆",
                    value: 33,
                },
                {
                    parentValue: 34,
                    name: "粉尘涉爆",
                    value: 35,
                },
                {
                    parentValue: 36,
                    name: "粉尘涉爆",
                    value: 37,
                },
                {
                    parentValue: 38,
                    name: "粉尘涉爆",
                    value: 39,
                },
                {
                    parentValue: 25,
                    name: "铝加工（深井铸造）",
                    value: 40,
                },
            ],
            institutionsArr2: [],
            licenseImageUploadLoading: false,
            action: config.API + "/resource/v1/resource/uploadPicture",
            headers: {
                token: localStorage.getItem("token"),
            },
            authCode: "",
            cityList: [],
            districtList: [],
            streetList: [],
            disabledSelect:false,//选择省直属时，灰置后面选择框
            formData: {
                name: "",
                enterType1: "",
                enterType2: "",
                enterType: "",
                userName: "",
                phone: "",
                targetBranchId: "", //注册到目标机构下
                addressDetail: "",
                businessLicenseImage: "",
                organCode: "",
                city: "",
                district: "",
                street: "",
            },
            rules: {
                name: { required: true, message: "请输入企业名称", trigger: "blur" },
                enterType1: {
                    required: true,
                    message: "请选择企业类型",
                    trigger: "change",
                },
                userName: {
                    required: true,
                    message: "请输入联系人姓名",
                    trigger: "blur",
                },
                city: { required: true, message: "请选择市", trigger: "change" },
                addressDetail: { required: true, message: "请输入详细地址", trigger: "blur" },
                organCode: [
                    {
                        required: true,
                        message: "请输入社会统一信用代码",
                        trigger: "blur",
                    },
                    { validator: organCodeValidator, trigger: "blur" },
                ],
                phone: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    { validator: accountValidator, trigger: "blur" },
                ],
            },
            isFirstGetAuthcode: true,
            Countdown: 0,
        };
    },
    mounted() {
        // 获取省市区机构
        this.findRegion(1, null);
    },
    methods: {
        handleEnterType1Change(val) {
            this.institutionsArr2 = this.institutionsArr.filter((item) => item.parentValue === val);
            this.formData.enterType2 = ""
        },
        findRegion(level, parentId) {
            let params = {
                parentId,
            };
            this.$api.fmBranch.findRegion(params).then((res) => {
                if (res.success && res.data) {
                    if (level == 1) {
                        this.cityList = res.data;
                    } else if (level == 2) {
                        this.districtList = res.data;
                    } else if (level == 3) {
                        this.streetList = res.data;
                    }
                }
            });
        },
        getAuthCode() {
            this.$refs.myForm.validateField("phone", (error) => {
                if (!error) {
                    const data = { account: this.formData.phone, type: 2 };
                    if (this.Countdown > 0) return;
                    this.Countdown = 60;
                    this.setCountdown();
                    this.$api.usercenter.getAuthCodePublic({ data });
                    this.isFirstGetAuthcode = false;
                }
            });
        },
        setCountdown() {
            this.CountdownInterval = setInterval(() => {
                this.Countdown--;
                if (this.Countdown <= 0) {
                    clearInterval(this.CountdownInterval);
                }
            }, 1000);
        },
        beforeLicenseImageUpload(file) {
            if (!["image/jpeg", "image/jpg", "image/png"].includes(file.type)) {
                this.$message.error("请上传jpg、png格式的图片");
                return false;
            }
            if (file.size / 1024 / 1024 > 5) {
                this.$message.error("图片大小不能超过5M");
                return false;
            }
            this.licenseImageUploadLoading = true;
        },
        licenseImageSuccess(res) {
            this.formData.businessLicenseImage = res.data;
            this.licenseImageUploadLoading = false;
            // this.$refs.myForm.clearValidate("businessLicenseImage");
        },
        handleCityChange(id) {
            this.$nextTick(()=>{
                if(this.$refs.selectCity.selectedLabel === '省直属'){
                    this.disabledSelect = true
                }else{
                    this.disabledSelect = false
                }
            })
            this.formData.district = "";
            this.formData.street = "";
            this.districtList = []
            this.streetList = []
            this.findRegion(2, id);
        },
        handleDistrictChange(id) {
            this.formData.street = "";
            this.streetList = []
            this.findRegion(3, id);
        },
        async submit() {
            try {
                await this.$refs.myForm.validate();
            } catch (error) {
                return;
            }
            let data = { ...this.formData };
            const params = { dataType: 0 }
            // 企业类型
            data.enterType = data.enterType2 || data.enterType1
            data.targetBranchId = data.street || data.district || data.city; //入驻到目标机构
            data.authCode = this.authCode;
            this.$api.usercenter.saveSdyjBranchApply(data,params).then((res) => {
                if (res.success) {
                    this.$alert("审核结果将以短信通知给您，请注意查收", "已提交", {
                        confirmButtonText: "确定",
                        center: true,
                        customClass: "messageBox",
                        confirmButtonClass: "dialog_confirmBtn",
                        callback: () => {
                            this.$refs.myForm.resetFields();
                            this.authCode = "";
                            // 企业类型
                            this.formData.enterType1 = "";
                            this.formData.enterType2 = "";
                            // 所属上级
                            this.formData.district = "";
                            this.formData.street = "";
                        },
                    });
                }
            });
        },
    },
};
</script>
<style lang="stylus" scoped>
.wdn-settled-main {
    background: #fff;
}

.settled-header {
    border-bottom: 1px solid #F1F6FF;
    padding-bottom: 32px;

    p {
        font-size: 20px;
        padding-left: 14px;
        position: relative;
        font-weight: 600;
        color: #1B2257;

        &::after {
            width: 4px;
            position: absolute;
            left: 0;
            top: 50%;
            content: '';
            height: 18px;
            background: #316FFF;
            transform: translateY(-50%);
        }
    }
}

.wd-settled {
    background: #fff;
    width: 1200px;
    margin: 0 auto;
    padding: 32px 48px 142px;
    box-sizing: border-box;

    .el-form {
        width: 682px;
        padding-top: 32px;
        margin: 0 auto;
    }
}

.messageBox {
    width: auto;

    .el-message-box__title span {
        font-size: 18px;
    }
}

.dialog_confirmBtn {
    width: 120px;
}

.numberInput /deep/ .el-input__inner {
    line-height: 1;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #0E5AFC;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background: #0E5AFC;
    border-color: #0E5AFC;
}

/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #0E5AFC;
}

/deep/ .el-input__inner {
    color: #333;
    border: 1px solid #DCDFE6;
    padding-left: 9px;

    &:focus {
        border: 1px solid #0E5AFC;
    }
}

.settled-footer {
    border-top: 1px solid #F1F6FF;
    padding-top: 30px;
    text-align: center;
}

.submitBtn {
    width: 182px;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    background: #0E5AFC;
    padding: 0;

    span {
        font-weight: 600;
    }
}

/deep/ .el-checkbox__label {
    color: #333333;
}

/deep/.el-form-item__label {
    color: #08224D;
    padding-right: 24px;
    text-align: right;
    line-height: 32px;
}

/deep/.avatar-uploader {
    display: flex;
    // align-items: center;
}

/deep/ .el-upload__tip {
    width: 200px;
    margin-left: 18px;
    line-height: 24px;
    font-size: 14px;
    color: #999999;
    word-break: normal;
}

/deep/.avatar-uploader .el-upload {
    width: 120px;
    height: 120px;
    background-color: #F8FAFF;
    border: 1px dashed #d9d9d9;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

/deep/.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

/deep/.avatar-uploader-icon {
    margin-top: 30px;
    font-size: 32px;
    color: #D6DEEA;
    // width: 120px;
    // height: 120px;
    // line-height: 120px;
    text-align: center;
}

.uploadTip {
    line-height: 14px;
    color: #95ACCF;
    margin-top: 5px;
}

/deep/.avatar {
    width: 120px;
    display: block;

    >img {
        width: 100%;
    }
}

.needRequred {
    margin-bottom: 0;

    /deep/ .el-form-item__label::before {
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
    }
}

.formItemTip {
    margin-left: 162px;
    margin-bottom: 24px;
}

/deep/ .el-form-item__error {
    position: static;
}

/deep/ .el-form-item {
    margin-bottom: 24px;

    .el-form-item__content {
        line-height: 32px;
    }
}

/deep/ .el-input__inner {
    padding: 0 8px;
}

.hasTip {
    margin-bottom: 7px;
}

.authCode {
    display: flex;
    justify-content: space-between;

    .getcodeBtn {
        width: 98px;
        border-width: 0;
        background: #0E5AFC;
        border-radius: 4px;

        &.getcodeBtnGray {
            background-color: #BFBFBF;
            color: #fff;
        }
    }
}

.selectItem {
    width: 168px;

    /deep/ .el-input__inner {
        &::placeholder {
            color: #333333;
        }
    }
}
</style>
